import React from "react";
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image';

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from '../components/elements/hero/hero.component';
import Testimonials from '../components/elements/testimonials/testimonials.component';
import BenefitsListings from '../components/elements/benefits/benefits-listing.component';
import TickList from '../components/elements/benefits/ticklist.component';
import PackagesListings from '../components/elements/packages/packages-listings.component';
import Banner from '../components/elements/banner/banner.component';
import Contact from '../components/pages/home/contact.component';

import strategry from '../images/Website/services/seo/strategry.svg'
import tech from '../images/Website/services/seo/tech-fixes.svg'
import onPage from '../images/Website/services/seo/on-page.svg'
import offPage from '../images/Website/services/seo/off-page.svg'
import speed from '../images/Website/services/seo/web-speed.svg'
import reviews from '../images/Website/services/seo/review-generation.svg'

import standard from '../images/Website/icons/standard.svg'
import unlimited from '../images/Website/icons/unlimited.svg'
import business from '../images/Website/icons/business.svg'


const WebPage = () => (
  <StaticQuery  
    query={query}
    render={data => {
    
    let content = {
      benefits: [
        {title: "Strategy session & keyword research", icon: strategry, text: "Our team will analyse your domain authority on Google and compare this with your competitors to determine the exact keywords for your target audience."},
        {title: "Technical website audit and fixes", icon: tech, text: "We’ll run a health audit on your website to identify any problems or issues that could potentially be preventing your site from ranking. We’ll then fix the issues for you."},
        {title: "On-Page SEO", icon: onPage, text: "Your web pages will be optimised for your target keywords. This will help Google to understand what your website is all about an which keywords you wish to rank for."},
        {title: "Off-Page SEO", icon: offPage, text: "Link building is the final piece of the SEO puzzle. You’ll get links from high authority websites which will tell Google and other search engines your website is trustworthy and high quality."},
        {title: "Website Speed Optimised", icon: speed, text: "Page speeds are an extremely important factor in 2021 when it comes to rankings so we’ll make sure your website runs fast."},
        {title: "Reviews & Social Media", icon: reviews, text: "Our essential plan also includes review generation and social media management. You’ll see a dramatic increase in online reviews and engagements on your social platforms which are all ranking factors for Google."},
      ],
      packages: {
        title: "Pricing",
        packages: [
          {
            title: "SEO Basic Plan", 
            icon: standard, 
            price: 500, 
            list: [
              "Bespoke SEO Strategy",
              "Website SEO audit",
              "Keyword Research",
              "Link Building",
              "On-Page SEO",
              "Off-Page SEO",
              "Monthly Reports",
            ],
            colour: 'primary'
          },
          {
            title: "Essential SEO Plan", 
            icon: unlimited, 
            price: 1000, 
            list: [
              "Bespoke SEO Strategy",
              "Website SEO audit", 
              "Keyword Research",
              "Competitor Analysis", 
              "Link Building",
              "On-Page SEO",
              "Off-Page SEO",
              "Google Reviews Generation",
              "Google My Business Optimisation",
              "Social Media Management",
              "Content Creation",
              "Monthly Reports",
            ],
            colour: 'secondary'
          },
          {
            title: "Standard SEO Plan", 
            icon: business, 
            price: 800, 
            list: [
              "Bespoke SEO Strategy",
              "Website SEO audit",
              "Keyword Research",
              "Competitor Analysis",
              "Link Building",
              "On-Page SEO",
              "Off-Page SEO",
              "Google Reviews Generation",
              "Google My Business Optimisation",
              "Monthly Reports",
            ],
            colour: 'primary'
          }
        ]
      }
    }

    return (
      <Layout noOverlayHeader>
        <SEO
          title="SEO Cambridge"
        />
        <Hero 
          title="Grow your business with the power of organic SEO."
          text="Get your business ranking to number #1 with our organic SEO services."
          button={{
            link: '/contact',
            title: 'Start today'
          }}
        />
        <section className="max-w-screen-xl mx-auto mb-10 md:mb-20 lg:mb-28 flex flex-wrap px-6 md:px-8 ">
          <div className="w-full md:w-5/12 md:pr-4 lg:pr-12 mb-5 md:mb-10">
            <Img className="shadow-2xl" fluid={data.intro.childImageSharp.fluid} alt="google analyitics results" />
          </div>
          <div className="w-full md:w-7/12">
            <h2 className="text-3xl md:text-4xl text-primary-default font-semibold mb-2 md:mb-4">Get your website found with Search Engine Optimisation</h2>
            <p className="text-lg md:text-2xl text-black-default max-w-3xl font-light mb-4 md:mb-4">Starting from <b>£500</b></p>
            <p className="font-light">
              With our SEO optimisation, you will rank on Google through your business keywords. You’ll know exactly what keywords we’re targeting and how many searches they get per month. This way you’ll know what value each keyword brings to your business and get an overview on how many potential customers you can generate solely through the use of organic SEO.
            </p>
          </div> 
            <p className="font-light">
              If you’re not using the correct keywords with the highest monthly searches then you’re likely to be missing out on a massive percentage of customers who will never be able to locate your business. Let work together on an SEO campaign and get your website ranking #1 so you can generate more traffic, leads, and sales.
            </p>
        </section>
        <BenefitsListings 
          title="So what's included with our SEO packages?"
          text="Check out some of the features you'll get working with us for your SEO"
          benefits={content.benefits}
          width="sm:w-1/2 md:w-1/3"
        />
        <TickList 
          title="Benefits of our SEO"
          ticks={[
              "You’ll see a steady increase in daily website traffic",
              "Your website will generate more leads and sales",
              "You’ll be able to outrank your competitors",
              "Monthly in-depth SEO reports keeping track of progress",
              "You’ll know exactly what we’ve worked on each month",
          ]}
          image={data.choose.childImageSharp.fluid}
        />
        <Testimonials secondary />
        <section className="max-w-screen-md mx-auto mb-10 px-6 md:px-8 text-center">
            <h2 className="text-3xl md:text-4xl text-primary-default font-semibold mb-2 md:mb-4 text-center">£600 One Off SEO Foundations</h2>
            <p className="font-light mb-5">
                Our SEO Foundations package helps to provide you with the platform to drive new traffic through your website. This one-time fee package is the perfect way to make a start on improving your Google rankings. It will put you on the right path to generate new enquiries and further profit from your website. We will also provide you with consultation and a step-by-step guide for you to follow on how to increase your SEO.
            </p>
            <ul className="flex flex-wrap justify-center items-center w-full">
                <li className="font-bold px-2">Website SEO audit</li>
                <li className="font-bold px-2">Keyword Research</li>
                <li className="font-bold px-2">Directory submissions</li>
                <li className="font-bold px-2">On-Page SEO</li>
            </ul>
        </section>
        <div id="maintenance">
          <PackagesListings {...content.packages} />
        </div>
        <Banner 
          title="Need a new website?" 
          text="Find out how you could increase sales, conversions and grow your business."
          image={{
            alt: "Website on a laptop",
            src: data.website.childImageSharp.fluid
          }}
          link1={{
            text: "find out more",
            url: "/web-design",
            color: "secondary"
          }}
        />
        <Contact 
          title="Lets talk"
          text="we would love to show you how we could help grow your business online."
          openingHours="Hours: Monday - Friday: 8:00am - 5:30pm"
          phone="07954 047 882"
          email="hello@volley.digital"
        />
      </Layout>
    )
  }} />
)

export default WebPage;

const query = graphql`
  query {
    tablet: file(relativePath: { eq: "Website/services/web/ely-exotics-mockup.png" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro: file(relativePath: { eq: "Website/analyitics-stats.jpg" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    choose: file(relativePath: { eq: "Website/services/seo-rankings.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    website: file(relativePath: { eq: "Website/need-a-new.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`