import React from 'react';

import './benefit.style.scss'

const Benefit = ({title, icon, text}) => (
    !text ? 
    <article className="py-10 bg-white-default mt-8 md:mt-12 h-32 relative">
        {icon && <img className="mx-auto -mt-16" alt={title} src={icon} />}
        <header className="benefit__title text-center absolute">
            {title && <h3 className="text-lg md:text-2xl text-black-default leading-tight" dangerouslySetInnerHTML={{__html: title}}/>}
        </header>
    </article>
    :
    <article className="p-10 bg-white-default mt-8 md:mt-12 relative">
        {icon && <img className="mx-auto -mt-16 mb-2" alt={title} src={icon} />}
        <header className="text-center">
            {title && <h3 className="text-lg md:text-2xl text-black-default mb-4 leading-tight" dangerouslySetInnerHTML={{__html: title}}/>}
            {text &&< p className="font-light" dangerouslySetInnerHTML={{__html: text}}/>}
        </header>
    </article>
);

export default Benefit;