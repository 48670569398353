import React, {useEffect, useRef} from 'react';
import BgEl from '../../elements/misc/bg-el.component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

import './hero.style.scss';

const Hero = ({title, text}) => {

  const titleRef = useRef(null);
  const textRef = useRef(null);
  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const circle3 = useRef(null);
  const circle4 = useRef(null);
  const circle5 = useRef(null);
  const circle6 = useRef(null);

  const scrubValue = 0.8;
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if ( titleRef && textRef && circle2 && circle3 && circle4 && circle5 && circle6) {
      gsap.to(titleRef.current, {
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top " + titleRef.current.offsetTop,
          scrub: scrubValue,
        },
        opacity: 0,
        y: -30,
      })
      gsap.to(textRef.current, {
        scrollTrigger: {
          trigger: textRef.current,
          start: "top " + textRef.current.offsetTop,
          scrub: scrubValue,
        },
        opacity: 0,
        y: -15,
      })
      gsap.to(circle3.current, {
        scrollTrigger: {
          trigger: circle3.current,
          start: "top " + circle3.current.offsetTop,
          scrub: scrubValue,
        },
        y: 20,
        x: 5,
      })
      gsap.to(circle1.current, {
        scrollTrigger: {
          trigger: circle1.current,
          start:  "top " + circle1.current.offsetTop,
          scrub: scrubValue,
        },
        y: -40,
        x: 25,
      })
      gsap.to(circle2.current, {
        scrollTrigger: {
          trigger: circle2.current,
          start:  "top " + circle2.current.offsetTop,
          scrub: scrubValue,
        },
        y: -60,
        x: -30,
      })
      gsap.to(circle4.current, {
        scrollTrigger: {
          trigger: circle4.current,
          start:  "top " + circle4.current.offsetTop,
          scrub: scrubValue,
        },
        y: 80,
        x: -30,
      })
      gsap.to(circle5.current, {
        scrollTrigger: {
          trigger: circle5.current,
          start:  "top " + circle5.current.offsetTop,
          scrub: scrubValue,
        },
        y: 80,
        x: 80,
      })
      gsap.to(circle6.current, {
        scrollTrigger: {
          trigger: circle6.current,
          start:  "top " + circle6.current.offsetTop,
          scrub: scrubValue,
        },
        y: 35,
      })
    }

  }, [ textRef, titleRef, circle1, circle2, circle3, circle4, circle5, circle6 ])
  
  return (
    <div className="default-hero mb-10 lg:mb-28 relative overflow-hidden">
      <section className="max-w-screen-2xl mx-auto px-6 md:px-8 text-center">
        <header className="max-w-xl mx-auto py-24 md:py-40 relative z-10">
          {title &&<h1 ref={titleRef} className="text-white-default inline-block mb-5 text-4xl sm:text-5xl font-semibold leading-tight relative z-10" dangerouslySetInnerHTML={{__html: title}}/>}
          {text && <p ref={textRef} className="text-white-200 leading-tight text-lg sm:text-2xl max-w-sm mx-auto relative z-10" dangerouslySetInnerHTML={{__html: text}} />}
          <div ref={circle3} className="absolute top-32 md:top-28 -left-16 md:-left-6 select-none">
            <BgEl type="circle" colour="#705CA4" />
          </div>
          <div ref={circle6} className="hidden md:block absolute right-0 transform scale-75 bottom-20 select-none">
            <BgEl type="circle" colour="#3F2E7F" opacity="opacity-50" />
          </div>
        </header>
        <div ref={circle1} className="hidden md:block absolute -right-16 top-8 select-none">
          <BgEl type="rectangle" colour="#705CA4" opacity="opacity-50" />
        </div>
        <div ref={circle2} className="absolute right-0 md:right-32 top-48 md:top-96 select-none">
          <BgEl type="circle" colour="#3F2E7F" />
        </div>
        <div ref={circle4} className="hidden md:block absolute -left-10 transform scale-150 top-64 select-none">
          <BgEl type="rectangle" colour="#3F2E7F" opacity="opacity-50" />
        </div>
        <div ref={circle5} className="absolute top-8 left-24 md:top-14 transform scale-150 select-none">
          <BgEl type="circle" colour="#705CA4" />
        </div>
      </section>
    </div>
  );
}

export default Hero;