import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import tickIcon from '../../../images/Website/icons/checked.svg';

const TickList = ({title, image, ticks}) => (
    <section className="flex flex-wrap mb-5 md:mb-0">
        <BackgroundImage 
            Tag="div"
            fluid={image}
            className="h-64 md:h-auto w-full md:w-5/12"
        />
        <div className="w-full md:w-7/12 py-10 md:py-20 xl:py-28 px-6 md:px-8 max-w-5xl bg-primary-default text-center md:text-left">
            <div className="mx-auto max-w-xl">
                {title && <h3 class="text-white-default inline-block mb-5 md:mb-8 text-4xl sm:text-5xl lg:text-6xl font-semibold leading-tight">{title}</h3>}
                {ticks && <ul>
                    {ticks.map((tick, i) => 
                        <li className="flex flex-wrap md:flex-nowrap items-center justify-center md:justify-start text-center md:text-left text-white-default" key={i}>
                            <span className={`${i%2 == 0 ? 'bg-secondary-default' : 'bg-tertiary-default'} flex-shrink-0 w-7 h-7 block flex flex-wrap items-center justify-center rounded-full ml-2 mr-1`}>
                                <img className="w-3.5" src={tickIcon} alt="tick" />
                            </span>
                            <span className="w-full md:w-auto text-white-default p-2 text-xl">{tick}</span>
                        </li>
                    )}
                </ul>}
            </div>
        </div>
    </section>
);

export default TickList;